export const BoatMiniSpecsComponent = ({ boat, features }) => {
  return (
    <>
      <div className="grid md:grid-flow-col md:grid-rows-2 md:grid-cols-3 grid-rows-2 grid-cols-1 bg-gray-200 p-5 text-sm rounded-xl mx-2">
        {features["Persons"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Persons</p>
            <p className="text-gray-700">{features["Persons"]}</p>
          </div>
        )}
        {features["Cabins"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Cabins</p>
            <p className="text-gray-700">{features["Cabins"]}</p>
          </div>
        )}

        {boat.construction_year !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Year of build</p>
            <p className="text-gray-700">{boat?.construction_year}</p>
          </div>
        )}
        {features["Bathrooms"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Bathrooms</p>
            <p className="text-gray-700">{features["Bathrooms"]}</p>
          </div>
        )}
        {features["Engine"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Engine</p>
            <p className="text-gray-700">{features["Engine"]}</p>
          </div>
        )}
        {features["Fuel tanks"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Fuel tanks</p>
            <p className="text-gray-700">{features["Fuel tanks"]}</p>
          </div>
        )}
      </div>
    </>
  );
};
