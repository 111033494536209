import { Navigation } from "../navigationBar/naviagationBar";
import { BoatCarouselComponent } from "./boatCarouselComponent";
import { BoatHeaderComponent } from "./boatHeaderComponent";
import { BoatSpecificationComponent } from "./boatSpecificationsComponent";
import { BoatPreviewSpecsComponent } from "./boatPreviewSpecsComponent";
import Footer from "../footer/Footer";
export const BoatPageComponent = ({
  image_length,
  boat,
  locationArr,
  selected,
  reserved,
  setSelected,
  features,
  equipment,
}) => {
  let img_array = Array.from({ length: image_length }).map((_, index) => {
    return (
      <div
        className="carousel transition-transform"
        style={{
          flexShrink: 0,
          scrollSnapAlign: "start",
          width: "100%",
          height: "100%",
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        id={`${boat.url_name}-${index}-id`}
        key={`${boat.url_name}-${index}-imagediv`}
      >
        <img
          key={`${boat.url_name}-${index}-imagecontainer`}
          className="rounded-lg block h-96 lg:h-510 w-full transition-transform"
          src={`/images/${boat.url_name}/image${index + 1}.webp`}
          alt={`${boat.url_name}-${index}`}
          loading="lazy"
          id={`${boat.url_name}-${index}-image`}
        />
      </div>
    );
  });

  return (
    <>
      <Navigation />
      {document
        .querySelector('meta[name="description"]')
        .setAttribute("content", boat.description)}
      {document
        .querySelector('meta[name="og:description"]')
        .setAttribute("content", boat.description)}
      <div className="flex flex-col gap-20 justify-center items-center min-h-screen bg-gray-100 p-10">
        <div className="max-w-7xl w-full  p-4 bg-white shadow-lg rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 w-full items-center">
            <div>
              <BoatCarouselComponent images={img_array} />
            </div>
            <div className="flex flex-col items-center justify-between">
              <div>
                <div className="flex justify-between items-center mb-2">
                  <BoatHeaderComponent
                    boat={boat}
                    locationArr={locationArr}
                    selected={selected}
                    reserved={reserved}
                    setSelected={setSelected}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4 text-center mb-4">
                <BoatPreviewSpecsComponent boat={boat} features={features} />
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-7xl w-full mx-auto p-4 bg-white shadow-lg rounded-lg">
          <BoatSpecificationComponent
            boat={boat}
            features={features}
            equipment={equipment}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};
