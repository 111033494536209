import axios from "axios";
// import env from "react-dotenv";
const apiRoute = process.env.REACT_APP_API_ROUTE;
const secret = process.env.REACT_APP_API_SECRET;

export const makeBooking = (
  url_name,
  first_name,
  last_name,
  email,
  phone_number,
  remarks,
  startDate,
  endDate,
  boat
) => {
  const bookingConfig = {
    method: "post",
    url: `${apiRoute}/book-boat/`,
    headers: {
      Authorization: secret,
    },
    data: {
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone_number: phone_number,
      remarks: remarks,
      startDate: startDate,
      toDate: endDate,
      boat: boat,
      provider: boat.provider || "Lefter",
    },
    params: {
      url_name: url_name,
    },
  };
  return axios(bookingConfig).then((res) => {
    return res.data;
  });
};
