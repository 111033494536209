import { Link } from "react-router-dom";

export const Navigation = () => {
  const toggleMobileNav = (e) => {
    e.preventDefault();
    const mobileNav = document.getElementById("mobile-nav");
    return mobileNav.style.display === "block"
      ? (mobileNav.style.display = "none")
      : (mobileNav.style.display = "block");
  };
  return (
    <>
      <nav className="border-gray-200">
        <div className="w-full flex flex-wrap items-center justify-center bg-white p-2">
          <button
            onClick={(e) => toggleMobileNav(e)}
            type="button"
            className="inline-flex items-center p-2 w-full h-10 justify-end text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
          >
            <img
              className="block md:p-0 h-8 absolute left-5 top-2"
              src="/images/logo.png"
              alt="heavenofsails"
              style={{
                color: "#fcc300",
              }}
            ></img>
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
          <ul className="md:flex text-xl flex-row justify-evenly align-middle items-center h-full w-full font-semibold hidden">
            <li>
              <img
                className="block md:p-0 h-8 text-2xl"
                src="/images/logo.png"
                alt="heavenofsails"
                style={{
                  color: "#fcc300",
                }}
              ></img>
            </li>
            <li>
              <Link to="/">
                <p className="hover:underline hover:underline-offset-4 pt-1">
                  Home
                </p>
              </Link>
            </li>
            <li>
              <Link to="/yacht-listings">
                <p className="hover:underline hover:underline-offset-4 pt-1">
                  Yacht Listings
                </p>
              </Link>
            </li>
            <li>
              <Link to="/faq">
                <p className="hover:underline hover:underline-offset-4 pt-1">
                  F.A.Q
                </p>
              </Link>
            </li>
            <li>
              <Link to="/contact">
                <p className="hover:underline hover:underline-offset-4 pt-1">
                  Contact us
                </p>
              </Link>
            </li>
          </ul>
        </div>
        <div className="hidden w-full md:block md:w-auto" id="mobile-nav">
          <ul className="md:hidden font-medium flex flex-col justify-end align-middle items-end p-4 text-right">
            <li>
              <Link
                to="/"
                className="block px-3 hover:underline hover:underline-offset-4"
              >
                <p>Home</p>
              </Link>
            </li>
            <li>
              <Link
                to="/yacht-listings"
                className="block px-3 hover:underline hover:underline-offset-4"
              >
                <p>Yacht Listings</p>
              </Link>
            </li>
            <li>
              <Link
                to="/faq"
                className="block px-3 hover:underline hover:underline-offset-4"
              >
                <p>F.A.Q</p>
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="block px-3 hover:underline hover:underline-offset-4"
              >
                <p>Contact us</p>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};
