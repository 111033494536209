import ContactForm from "../../components/contactComponents/contactForm";
import Footer from "../../components/footer/Footer";
import { Navigation } from "../../components/navigationBar/naviagationBar";

export default function ContactPage() {
  return (
    <main className="bg-gray-100/75 h-screen">
      <Navigation />
      <div className="w-full h-3/4 flex justify-center align-middle items-center">
        <div className="w-3/5">
          <ContactForm />
        </div>
      </div>
      <Footer />
    </main>
  );
}
