import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { BoatBoilerPage } from "./pages/boatPage/boatBoiler";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Home } from "./pages/homePage/Home";
import { BookingPage } from "./pages/booking/booking";
import { BookingSuccessfulComponent } from "./components/bookingComponents/bookingSuccessfulComponent";
import FAQpage from "./pages/FAQ/FAQpage";
import { YachtListingsPage } from "./pages/yacht-listings/YachtListings";
import ContactPage from "./pages/contactPage/Contact";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/yacht-listings" element={<YachtListingsPage />} />
        <Route path="/faq" element={<FAQpage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/boats/:id" element={<BoatBoilerPage />} />
        <Route path="/booking/:id" element={<BookingPage />} />
        <Route
          path="/booking/successful/:id"
          element={<BookingSuccessfulComponent />}
        />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
