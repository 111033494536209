import axios from "axios";
// import env from "react-dotenv";
// const apiRoute = "https://temp-tl-backend.netlify.app/.netlify/functions/api";
const apiRoute = process.env.REACT_APP_API_ROUTE;
const secret = process.env.REACT_APP_API_SECRET;
export const RequestBoatData = async () => {
  const boatsDataConfig = {
    method: "get",
    url: `${apiRoute}/get-boats`,
    headers: {
      Authorization: secret,
    },
  };

  return await axios(boatsDataConfig).then(async (res) => {
    return res.data;
  });
};
export const makeRequestBoatData = async () => {
  const data = await RequestBoatData();
  return data;
};

export const searchBoatData = async (boat) => {
  const boatDataConfig = {
    method: "get",
    url: `${apiRoute}/get-boat`,
    headers: {
      Authorization: secret,
    },
    params: {
      url_name: boat,
    },
  };
  return await axios(boatDataConfig).then(async (res) => {
    return res.data;
  });
};

export const getBookedDates = async (boat) => {
  const bookDateConfig = {
    method: "post",
    url: `${apiRoute}/closed-dates`,
    headers: {
      Authorization: secret,
    },
    data: {
      boat_url: boat,
    },
  };
  return await axios(bookDateConfig).then(async (res) => {
    return res.data;
  });
};
