export default function Footer() {
  return (
    <footer>
      <div className="w-full h-40 grid md:grid-cols-3 grid-cols-2 bg-white text-black text-center justify-items-center align-middle items-center">
        <img
          className="block md:p-0 h-8 text-2xl"
          src="/images/logo.png"
          alt="heavenofsails"
          style={{
            color: "#fcc300",
          }}
        ></img>
        <div className="flex flex-col w-full p-10 text-left">
          <p className="text-md font-semibold underline align-baseline">
            Contact information
          </p>
          <p className="text-sm font-thin">
            Email:{" "}
            <a
              href="mailto:customersupport@heavenofsails.com"
              className="underline underline-offset-2 hover:!text-sky-500"
            >
              customersupport@heavenofsails.com
            </a>
          </p>
          <p className="text-sm font-thin">
            Office hours:{" "}
            <span className="underline underline-offset-2">
              10am - 8pm Mon to Fri
            </span>
          </p>
          <p className="text-sm font-thin">
            Office hours:{" "}
            <span className="underline underline-offset-2">
              12am - 3pm Sat-Sun
            </span>
          </p>
        </div>
        <div className="flex flex-col w-full p-10 text-left">
          <p className="text-md font-semibold underline">Featured yachts</p>
          <a className="text-sm font-thin" href="/boats/dufour-382-sun">
            Dufour 382 GL Sun
          </a>
          <a
            className="text-sm font-thin"
            href="/boats/bavaria-cruiser-46-rozerina"
          >
            Bavaria Cruiser 46 Rozerina
          </a>
          <a
            className="text-sm font-thin"
            href="/boats/sun-odyssey-490-achilleas"
          >
            Sun Odyssey 490 Achilleas
          </a>
        </div>
        {/* <div className="flex flex-col w-full p-10 text-left">
          <p className="text-md font-semibold underline">Featured yachts</p>
          <a className="text-sm font-thin" href="/boats/dufour-382-sun">
            Dufour 382 GL Sun
          </a>
          <a
            className="text-sm font-thin"
            href="/boats/bavaria-cruiser-46-rozerina"
          >
            Bavaria Cruiser 46 Rozerina
          </a>
          <a
            className="text-sm font-thin"
            href="/boats/sun-odyssey-490-achilleas"
          >
            Sun Odyssey 490 Achilleas
          </a>
        </div> */}
      </div>
    </footer>
  );
}
