import axios from "axios";
const apiRoute = process.env.REACT_APP_API_ROUTE;
const secret = process.env.REACT_APP_API_SECRET;
export default async function sendContactEmail(
  email,
  firstName,
  lastName,
  message
) {
  const config = {
    method: "post",
    url: `${apiRoute}/contact`,
    headers: {
      Authorization: secret,
    },
    data: {
      email: email,
      firstName: firstName,
      lastName: lastName,
      message: message,
    },
  };
  console.log(config);
  return await axios(config).then(async (res) => {
    return res.data;
  });
}
