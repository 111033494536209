import Footer from "../../components/footer/Footer";
import { Navigation } from "../../components/navigationBar/naviagationBar";
import TextWithLineBreaks from "../../components/TextLineWithBreaks/TextLineWithBreaks";
import { questions } from "../../utilities/faq-questions";

export default function FAQpage() {
  const handleOnClick = (e) => {
    const contentElement = document.getElementById(`${e.target.id}-content`);
    contentElement.style.display === "block"
      ? (contentElement.style.display = "none")
      : (contentElement.style.display = "block");
  };
  return (
    <main className="bg-gray-200">
      <Navigation />
      <div className="w-full h-fit flex justify-center align-middle my-4">
        <div className="w-3/4 md:w-1/2 bg-white shadow-sm p-5 m-5 rounded-lg h-fit">
          <h1 className="md:text-4xl text-xl font-semibold border-b-2 border-b-black p-2">
            Frequently asked questions
          </h1>
          <div className="flex flex-col text-left gap-3 p-2 md:text-lg text-sm">
            {questions.map((question, _) => {
              return (
                <>
                  <button
                    className="text-gray-600 text-left underline underline-offset-2 hover:text-sky-500 focus:text-sky-500"
                    onClick={(e) => handleOnClick(e)}
                    id={`show-${question[0]}`}
                  >
                    ➤ {question[0]}
                  </button>
                  <div
                    id={`show-${question[0]}-content`}
                    className="text-left !pl-5 p-0 text-slate-500"
                    style={{ display: "none" }}
                  >
                    <TextWithLineBreaks text={question[1]}></TextWithLineBreaks>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
