export const BoatPreviewSpecsComponent = ({ boat, features }) => {
  return (
    <>
      <div>
        <p className="text-gray-600">Persons</p>
        <p className="font-bold">{features["Persons"]}</p>
      </div>
      <div>
        <p className="text-gray-600">Berths</p>
        <p className="font-bold">{features["Berths"]}</p>
      </div>
      <div>
        <p className="text-gray-600">Cabins</p>
        <p className="font-bold">{features["Cabins"]}</p>
      </div>
      <div>
        <p className="text-gray-600">Length</p>
        <p className="font-bold">{features["Length"]}</p>
      </div>
      <div>
        <p className="text-gray-600">Year of build</p>
        <p className="font-bold">{boat?.construction_year}</p>
      </div>
      <div>
        <p className="text-gray-600">Bathrooms</p>
        <p className="font-bold">{features["Bathrooms"]}</p>
      </div>
      <div>
        <p className="text-gray-600">Water tanks</p>
        <p className="font-bold">{features["Water tanks"]}</p>
      </div>
    </>
  );
};
