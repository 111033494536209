import { BoatCardComponent } from "./BoatCardComponent";
import { Link } from "react-router-dom";
import { Navigation } from "../navigationBar/naviagationBar";
import Footer from "../footer/Footer";
export const HomePageComponent = ({ boatArray }) => {
  return (
    <>
      <Navigation />
      <div className="flex justify-center flex-col align-middle justify-items-center border-b-2 border-black">
        <div
          className="flex justify-center items-center text-center rounded-lg m-4 bg-center"
          style={{
            backgroundImage: `url("./images/home_background.jpg")`,
            height: "75vh",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className=" border-b-2">
          <div
            className="text-center md:text-lg md:font-semibold flex justify-center align-middle items-center md:m-5  md:p-5 p-3 m-2 bg-gray-400/10 rounded-md text-sm md:h-1/4"
            // style={{ height: "25vh" }}
          >
            <p className="md:w-1/2 text-black/50 md:p-4 p-2">
              HeavenOfSails makes it easy to find the yacht charter vacation
              that is right for you. We combine numerous of yacht listings with
              local destination information, sample itineraries and experiences
              to deliver the world's most comprehensive yacht charter website.
            </p>
          </div>
        </div>
        <div
          className={`grid items-center justify-items-center  2xl:grid-cols-3 lg:grid-cols-2 gap-4 text-center md:text-xl md:p-5 grid-cols-1`}
        >
          {boatArray
            .sort((a, b) => b.carousel_images - a.carousel_images)
            .filter((_, index) => index < 3)
            .map((boat, index) => {
              return (
                <Link
                  key={index}
                  to={`/boats/${boat.url_name}`}
                  className="text-center rounded-lg md:p-6 p-2 md:m-0 m-2 drop-shadow-xl shadow-lg hover:shadow hover:drop-shadow  hover:shadow-black hover:scale-95 transition-all"
                  style={{
                    backgroundColor: "#f8f6f6",
                  }}
                >
                  <BoatCardComponent boat={boat} />
                </Link>
              );
            })}
        </div>
        <div className="w-full flex justify-center my-3">
          <a
            href="/yacht-listings"
            className="cursor-pointer rounded-lg bg-slate-100 border-white hover:border-sky-400 hover:font-semibold font-medium border-2 hover:bg-sky-400 transition-colors text-lg m-5 hover:text-white p-2 w-fit"
          >
            See more
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
};
