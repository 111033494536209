import { useEffect, useState } from "react";
import { makeRequestBoatData } from "../../utilities/requestBoatData";
import { Loading } from "../loading/Loading";
import { YachtListingsComponent } from "../../components/YachtListings/YachtPageComponent";

export const YachtListingsPage = () => {
  const [boatArray, setBoatArray] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    makeRequestBoatData().then((res) => {
      setBoatArray(res);
      setLoaded(true);
    });
  }, []);
  return (
    <>
      {loaded ? <YachtListingsComponent boatArray={boatArray} /> : <Loading />}
    </>
  );
};
