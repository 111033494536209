export const Loading = () => {
  return (
    <>
      <div
        className="bg-white w-screen h-screen flex justify-center items-center flex-col"
        id="loading-page"
      >
        <svg
          fill="#00c7fc"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="64px"
          height="64px"
          viewBox="-62.84 -62.84 574.56 574.56"
          xmlSpace="preserve"
          stroke="#00c7fc"
          strokeWidth="0.0044887500000000006"
          transform="rotate(0)"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke="#CCCCCC"
            strokeWidth="0.89775"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <g>
                {" "}
                <polygon points="259.671,35.018 259.671,302.869 394.926,302.869 "></polygon>{" "}
                <path d="M214.273,302.869V38.168C79.65,90.343,19.308,289.463,20.439,302.869H214.273z"></path>{" "}
                <polygon points="0,332.309 100.317,413.857 348.557,413.857 448.875,332.309 "></polygon>{" "}
              </g>{" "}
            </g>{" "}
          </g>
        </svg>
        <p className="text-blue-200 text-4xl text-center">
          Preparing your trip
        </p>
        <p className="text-blue-200 text-xl text-center">
          We will be right with you
        </p>
      </div>
    </>
  );
};
