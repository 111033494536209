import { useState } from "react";
import sendContactEmail from "../../utilities/sendContactEmail";
import { useNavigate } from "react-router-dom";

export default function ContactForm() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [canSubmit, setCanSubmit] = useState(true);
  function validateForm(e) {
    e.preventDefault();
    const first_name_container = document.getElementById(
      "first_name_container"
    );
    if (firstName.length <= 1) {
      setCanSubmit(false);
      const error_message = document.createElement("span");
      error_message.textContent = "First name is required";

      if (first_name_container.children.length >= 1) {
        first_name_container.removeChild(first_name_container.firstChild);
      }
      first_name_container.appendChild(error_message);
    } else {
      setCanSubmit(true);
      first_name_container.style.display = "none";
    }
    const last_name_container = document.getElementById("last_name_container");
    if (lastName.length <= 1) {
      setCanSubmit(false);
      const error_message = document.createElement("span");
      error_message.textContent = "Last name is required";

      if (last_name_container.children.length >= 1) {
        last_name_container.removeChild(last_name_container.firstChild);
      }
      last_name_container.appendChild(error_message);
    } else {
      setCanSubmit(true);
      last_name_container.style.display = "none";
    }
    const email_container = document.getElementById("email_container");
    if (email.length <= 1) {
      setCanSubmit(false);
      const error_message = document.createElement("span");
      error_message.textContent = "Email is required";

      if (email_container.children.length >= 1) {
        email_container.removeChild(email_container.firstChild);
      }
      email_container.appendChild(error_message);
    } else {
      setCanSubmit(true);
      email_container.style.display = "none";
    }
    const message_container = document.getElementById("message_container");
    if (message.length <= 1) {
      setCanSubmit(false);
      const error_message = document.createElement("span");
      error_message.textContent = "Message is required";

      if (message_container.children.length >= 1) {
        message_container.removeChild(message_container.firstChild);
      }
      message_container.appendChild(error_message);
    } else {
      setCanSubmit(true);
      message_container.style.display = "none";
    }
  }
  const navigate = useNavigate();
  const useHandleSubmit = () => {
    if (!canSubmit) return;
    return sendContactEmail(email, firstName, lastName, message).then(
      async (_) => {
        return navigate("/contact-success");
      }
    );
  };
  return (
    <>
      <form
        className="flex flex-col w-full justify-center text-left p-4 bg-white rounded-md"
        onSubmit={useHandleSubmit}
      >
        <p className="text-xl font-semibold pb-2">Contact us!</p>
        <div>
          <div className="text-red-500" id="email_container"></div>
          <label htmlFor="email-form" className="font-medium">
            Email:
          </label>
          <input
            name="email-form"
            type="email"
            placeholder="youremail@example.com"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <div className="text-red-500" id="first_name_container"></div>
          <label htmlFor="firstName" className="font-medium">
            First name:
          </label>
          <input
            name="firstName"
            type="text"
            placeholder="John"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 transition-all"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <div className="text-red-500" id="last_name_container"></div>

          <label htmlFor="lastName" className="font-medium">
            Last name:
          </label>
          <input
            name="lastName"
            type="text"
            placeholder="Smith"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div className="border-b-4">
          <div className="mb-2">
            <div className="text-red-500" id="message_container"></div>
            <label htmlFor="message-form" className="font-medium">
              Message
            </label>
            <textarea
              name="message-form"
              placeholder="Type your message here..."
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 resize-none"
              rows={5}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </div>
        <div>
          <button
            // type="submit"
            className="bg-sky-400 w-fit text-md text-center text-white p-4 rounded-md my-5 border-2 font-medium hover:bg-sky-500 hover:border-gray-400/10 hover:font-semibold transition-all"
            onClick={validateForm}
          >
            Contact us
          </button>
        </div>
      </form>
    </>
  );
}
