export const questions = [
  [
    "I have never sailed. Is it for me?",
    "Yes! If you have never sailed and you would like to try, our offer is for you. We will advise you on the yacht, the route and we will easily guide you through all formalities. Our skipper will steer the yacht and will show you places no-one has ever seen - and you will enjoy yachting.",
  ],
  [
    "Is sailing safe?",
    "Sailing for fun in South Europe is safer than driving a car :). The skipper is qualified, experienced and he/she knows the basin and the yacht well. The type of cruise is adapted to your capabilities and skills so that you could feel safe and comfortable.",
  ],
  [
    "Whom will I sail with?",
    "You can sail with who you want! We rent the entire yacht to one client – we don't sell single cabins or beds. You can sail with your family, your friends or work colleagues. If you decide to sail with a skipper, he will be on board, too. We guarantee the skipper will have expert knowledge, high personal culture and good sense of humor.",
  ],
  [
    "Who will be the skipper",
    "If you don't have proper permissions/licenses we offer only professional skippers who know their yacht perfectly, insured and legally working. It guarantees high quality service and safety. Every skipper knows his/her basin perfectly and is an indispensable guide to tourist attractions and local pubs.",
  ],
  [
    "How many people can sail?",
    "Of course it depends on the yacht size. We offer yachts for 2 up to 18 people. The most popular yachts have two, three and four cabins for 4 up to 10 people. Each cabin can accommodate two people on a double bed or a bunk. Two additional places to sleep are often available in the living room (called a mess) on a sofa. Some yachts also have cabins for a single person or two persons, so called skipper cabins - a bit smaller and usually with a separate entrance.",
  ],
  [
    "When to sail?",
    "In Europe, the season begins in May and ends at the end of September. After the season, the weather is changeable, and conditions are more challenging for avid sailors. The peak season is in July and August, when prices are the highest and there are many people everywhere. June and May mean a long day and fine weather, good for those who cannot go anywhere beyond holidays. In September, the weather is still fine, the water is warmer than in spring, but days are shorter.",
  ],
  [
    "Where does the cruise begin and end?",
    "Cruises begin and end at the port (base) where the chosen yacht is based. For an additional payment and upon an agreement, you can return the yacht to another base.",
  ],
  [
    "What would I eat on the yacht?",
    "Yachts have fully equipped kitchens (called a galley). Depending on the yacht's size, the kitchen can be equipped differently, but the standard are fridge, cooker, plates, cutlery and jugs. The bigger and newer the yacht, the better equipment (e.g. oven, microwave oven, dishwasher, freezer, ice cube maker, etc.).",
  ],
  [
    "Can I sail with kids?",
    "Of course, you can sail with kids - you will have children's life vests, you can also request a so-called safety net protecting children from falling out (can be additionally paid). Kids love to sail on yachts, you just need to plan a shorter route, otherwise they will get bored.",
  ],
  [
    "Will I get sea sickness?",
    "Everyone can get sea sickness, but is easy extremely rare when you sail recreationally. You just need to plan the route properly to avoid it.",
  ],
  [
    "Is there access to the internet on the yacht?",
    "Most of yachts have Wi-Fi with access to the Internet. This is mostly for free but sometimes it is paid additionally. Using our search engine, you can find yachts with the access to the Internet included in the price.",
  ],
  [
    "Is there a bathroom on the yacht?",
    "Yes all yacht's cabins are equipped with bathrooms with showers - one bathroom on smaller yachts up to several on the bigger ones. The more expensive the yacht, the bigger and more comfortable the bathrooms.",
  ],
  [
    "Should I have my plates, jugs etc. with me?",
    "Cabin yachts have fully equipped kitchens. You don't have to take: plates, jugs, cutlery, etc. with you. Of course, the more expensive the yacht, the kitchen is better equipped.",
  ],
  [
    "Are there bedding and towels available on the yacht?",
    "Bedding and towels are a standard. Sometimes there is an additional charge for washing.",
  ],
  [
    "Is there power supply on the yacht?",
    "Most of yachts have 220 V power sockets, but they can be used only in the port (unless we choose a yacht with a power generator). Outside ports, we have 12 V sockets like in cars.",
  ],
  [
    "When is the best season to reserve a yacht?",
    "If you are not choosy or you don't plan to sail beyond the season, you can find good LAST MINUTE offers a week before the planned holidays. However, if you are interested in a specific yacht, and in the peak season, you should make the reservation earlier (winter would be the best). Catamarans should be reserved in spring at the latest, because there are not so many of them, and they can be unavailable in summer.",
  ],
  [
    "How do I find a yacht suitable for me?",
    "Specify where, when and how many people will sail. Select options in our search engine at the home page. Compare the results, and then clarify your requirements using the search engine on the left.",
  ],
  [
    "How do I choose a yacht",
    "Apart from obvious criteria like number of places, basin, cruise time and price you should consider the following:\n• yacht age - the newer, the better equipped and more reliable, but more expensive \n• yacht size - the larger, the more comfortable, but more difficult to operate \n• water reservoir capacity - larger reservoirs allow you to avoid frequent sailing to the port\n Equipment:\n•rolled mainsail on sail yachts - comfortable for a less experienced crew, but not reliable<br>• batten mainsail on sail yachts - requires more work but is more reliable\n• chart plotter in the cockpit - it's really comfortable\n• bow thruster - useful on larger yachts, not necessary on the smaller ones\n• air-conditioning - sometimes useful in ports, but is not necessary on water\n• heating - it is really useful beyond the season\n• electric toilet - important especially for women (you use it once, you will never have any other)\nAdditional options:\n• deposit insurance - you can really use this option.",
  ],
  [
    "What is a deposit?",
    "A deposit is a securing amount in case of potential damages to the yacht by a client. If the client returns the yacht undamaged, the entire deposit is returned to the him/her. The deposit is usually € 1000 up to 2500. The amount is usually secured at the client credit card account. Sometimes it is paid in cash. If you want to limit the liability for damages to the yacht to a lower amount, you can use a so called deposit insurance, which paid additionally will relieve you of paying the deposit or reduces it to a lower amount.",
  ],
  [
    "What document will I receive when reserving the yacht?",
    "1.You will be emailed an agreement after making the reservation.\n2.If you took out a charter resignation insurance, you will receive an insurance certificate after giving the data of insured persons.\n3.You will be confirmed of the payment by email.\n4.After paying the whole amount and giving the crew member list, you will receive a Boarding Pass entitling you to take over the yacht with guidelines on the travel and necessary contact information.\n5.If you took out a travel insurance, will also receive an insurance certificate.",
  ],
];
