import { Fragment } from "react";

function TextWithLineBreaks(props) {
  const textWithBreaks = props.text.split("\n").map((text, index) => (
    <Fragment key={index}>
      <p>{text}</p>
      <br />
    </Fragment>
  ));

  return <div>{textWithBreaks}</div>;
}

export default TextWithLineBreaks;
