import { useEffect, useState } from "react";

import {
  getBookedDates,
  searchBoatData,
} from "../../utilities/requestBoatData";
import { useParams } from "react-router-dom";
import { BoatPageComponent } from "../../components/boatComponents/boatPageComponent";
import { Loading } from "../loading/Loading";
export const BoatBoilerPage = () => {
  const [boat, setBoat] = useState({});
  const [features, setFeatures] = useState({});
  const [equipment, setEquipment] = useState([]);
  const [images, setImages] = useState(0);
  const [location, setLocation] = useState("");
  const [selected, setSelected] = useState([]);
  const [reserved, setReserved] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    searchBoatData(id).then((res) => {
      setBoat(res);
      setFeatures(res.features);
      setEquipment(res.features.equipment);
      setImages(res.carousel_images);
      setLocation(res.location);
      setLoaded(true);
    });
    getBookedDates(boat.name).then((res) => {
      if (res === undefined) return;
      let cached = [];
      res.map((item, index) => {
        let s = item.split(" - ")[0];
        let e = item.split(" - ")[1];
        return (cached[index] = {
          startDate: new Date(s),
          endDate: new Date(e),
        });
      });
      setReserved(cached);
    });
  }, [boat.name, id]);
  const locationArr =
    location !== undefined
      ? location.split(",")
      : setLocation("No location provided");
  return (
    <>
      {loaded ? (
        <BoatPageComponent
          boat={boat}
          image_length={images}
          selected={selected}
          setSelected={setSelected}
          reserved={reserved}
          locationArr={locationArr}
          features={features}
          equipment={equipment}
        />
      ) : (
        <Loading />
      )}
    </>
  );
};
