import TextWithLineBreaks from "../TextLineWithBreaks/TextLineWithBreaks";

export const BoatSpecificationComponent = ({ boat, features, equipment }) => {
  const handleMapToggle = (e) => {
    e.preventDefault();
    const mapElement = document.getElementById("map-rent");
    return mapElement.style.display === "block"
      ? (mapElement.style.display = "none")
      : (mapElement.style.display = "block");
  };
  return (
    <>
      <p className="bg-blue-800 p-2 text-center text-white text-3xl font-bold">
        Information
      </p>
      <br></br>
      <p className="pt-2 pb-1 text-black text-xl font-semibold">Description</p>
      <div className="text-md text-gray-700 bg-gray-300 rounded-md p-4">
        <TextWithLineBreaks text={boat.description} /> <br></br>
        <div className="text-blue-500">
          <button onClick={(e) => handleMapToggle(e)}>
            To rent from {boat.location}
          </button>
        </div>
        <div
          className="map z-20 bg-gray-200 pl-2 pr-2 m-0 pb-0 rounded-lg"
          style={{ display: "none", position: "absolute" }}
          id="map-rent"
        >
          <p className="text-right w-full">
            <button onClick={(e) => handleMapToggle(e)}>
              <svg
                fill="#000000"
                height="12px"
                width="12px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 460.775 460.775"
                xmlSpace="preserve"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"></path>{" "}
                </g>
              </svg>
            </button>
          </p>

          <iframe
            className="bg-gray-200 p-2"
            title="Google Maps Embed"
            width={300}
            height={250}
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJz4a_WoO-oRQRhmi0DaQ8keg&key=${process.env.REACT_APP_MAPS_API}`}
          ></iframe>
        </div>
      </div>
      <br></br>
      <p className="pt-2 pb-1 text-black text-xl font-semibold">
        Specifications
      </p>
      <div className="grid lg:grid-flow-col lg:grid-rows-3 lg:grid-cols-3 grid-rows-2 grid-cols-2  bg-gray-300 rounded-xl p-2">
        {features["Persons"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Persons</p>
            <p className="text-gray-700">{features["Persons"]}</p>
          </div>
        )}
        {features["Berths"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Berths</p>
            <p className="text-gray-700">{features["Berths"]}</p>
          </div>
        )}
        {features["Cabins"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Cabins</p>
            <p className="text-gray-700">{features["Cabins"]}</p>
          </div>
        )}
        {features["Length"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Length</p>
            <p className="text-gray-700">{features["Length"]}</p>
          </div>
        )}
        {boat.construction_year !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Year of build</p>
            <p className="text-gray-700">{boat?.construction_year}</p>
          </div>
        )}
        {features["Bathrooms"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Bathrooms</p>
            <p className="text-gray-700">{features["Bathrooms"]}</p>
          </div>
        )}
        {features["Water tanks"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Water tanks</p>
            <p className="text-gray-700">{features["Water tanks"]}</p>
          </div>
        )}
        {features["Engine"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Engine</p>
            <p className="text-gray-700">{features["Engine"]}</p>
          </div>
        )}
        {features["Fuel tanks"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Fuel tanks</p>
            <p className="text-gray-700">{features["Fuel tanks"]}</p>
          </div>
        )}
        {features["Width"] !== undefined && (
          <div className="flex flex-row gap-2">
            <p className="font-semibold">Width</p>
            <p className="text-gray-700">{features["Width"]}</p>
          </div>
        )}
      </div>
      <p className="pt-2 pb-1 text-black text-xl font-semibold">Equipment</p>
      <div className="grid lg:grid-flow-row xl:grid-rows-4 xl:grid-cols-5 lg:grid-cols-3 lg:grid-rows-4 grid-cols-2 bg-gray-300 rounded-xl p-2 lg:gap-x-2 gap-x-0">
        {equipment.map((eq, index) => {
          return (
            <div key={`container-${eq}-${index}`}>
              <p className="text-gray-700" key={index}>
                {eq}
              </p>
            </div>
          );
        })}
      </div>
      <p className="pt-2 pb-1 text-black text-xl font-semibold">Extras</p>
      <div className=" bg-gray-300 rounded-xl p-2">
        {features.extras.map((extra, index) => {
          return (
            <div
              key={`container-${extra}-${index}`}
              className="grid grid-cols-2 justify-items-stretch p-2 border-b-2 border-gray-500"
            >
              <div>
                <p>{extra[0]}</p>
              </div>
              <div
                className="flex justify-end"
                key={`container-price-${index}`}
              >
                <p>{extra[1]}</p>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
