export const getPrice = (DateNow, prices) => {
  if (typeof DateNow == "string") {
    DateNow = new Date(DateNow).valueOf();
  }
  for (let i = 0; i < prices.length - 1; i++) {
    let startDateParts = Object.keys(prices[i])[0].split("/");
    const startDate = new Date(
      +startDateParts[2],
      startDateParts[1] - 1,
      +startDateParts[0]
    ).valueOf();
    if (DateNow < startDate) return Object.values(prices[i])[0];
  }
  return Object.values(prices[0])[0];
};
