import { useState } from "react";
import "../../index.css";
export const BoatCarouselComponent = ({ images }) => {
  const [activeIndex, setActive] = useState(0);

  const carouselContainer = Array.from(document.querySelectorAll(".carousel"));
  const isDisabled = (btn) => {
    switch (btn) {
      case "next":
        return activeIndex >= images.length - 1;
      case "prev":
        return activeIndex === 0;
      default:
        return;
    }
  };
  const handlePrevious = () => {
    setTimeout(() => {
      const newIndex = activeIndex - 1;
      if (newIndex < 0) {
        setActive(0);
      } else {
        setActive(newIndex);

        carouselContainer.map((carousel, _) => {
          if (carousel.computedStyleMap !== undefined) {
            const transform =
              carousel.computedStyleMap().get("transform")[0] !== undefined
                ? carousel.computedStyleMap().get("transform")[0].x.value +
                  carousel.scrollWidth
                : carousel.scrollWidth;
            return (carousel.style.transform = `translate3d(${transform}px, 0, 0)`);
          } else {
            const elem = document.getElementById(carousel.id);
            const transform =
              elem.style.transform.split(/\w+\(|\);?/)[1] !== undefined
                ? elem.style.transform
                    .split(/\w+\(|\);?/)[1]
                    .split(/,\s?/g)
                    .map(parseInt)[0] + carousel.scrollWidth
                : carousel.scrollWidth;
            return (carousel.style.transform = `translate3d(${transform}px, 0, 0)`);
          }
        });
      }
    }, 100);
  };
  const handleNext = () => {
    setTimeout(() => {
      const newIndex = activeIndex + 1;
      if (newIndex > images.length) {
        setActive(images.length);
      } else {
        setActive(newIndex);
        carouselContainer.map((carousel, _) => {
          if (carousel.computedStyleMap !== undefined) {
            const transform =
              carousel.computedStyleMap().get("transform")[0] !== undefined
                ? carousel.computedStyleMap().get("transform")[0].x.value -
                  carousel.scrollWidth
                : -carousel.scrollWidth;
            return (carousel.style.transform = `translate3d(${transform}px, 0, 0)`);
          } else {
            const elem = document.getElementById(carousel.id);
            const transform =
              elem.style.transform.split(/\w+\(|\);?/)[1] !== undefined
                ? elem.style.transform
                    .split(/\w+\(|\);?/)[1]
                    .split(/,\s?/g)
                    .map(parseInt)[0] - carousel.scrollWidth
                : -carousel.scrollWidth;
            return (carousel.style.transform = `translate3d(${transform}px, 0, 0)`);
          }
        });
      }
    }, 100);
  };

  return (
    <>
      <div
        id="carousel-container"
        className="relative h-510 w-full overflow-hidden rounded-xl flex justify-center items-center"
      >
        <button
          id="prev-button"
          className="!absolute top-1/2 left-4 -translate-y-1/2 z-20 fill-blue-gray-900"
          onClick={() => {
            handlePrevious();
          }}
          disabled={isDisabled("prev")}
        >
          <svg
            id="prev-button-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={`${
              isDisabled("prev")
                ? "fill-white stroke-white opacity-50"
                : "fill-white stroke-white"
            }`}
          >
            <polygon points="15.293 3.293 6.586 12 15.293 20.707 16.707 19.293 9.414 12 16.707 4.707 15.293 3.293" />
          </svg>
        </button>
        <button
          id="next-button"
          className="!absolute top-1/2 !right-4 -translate-y-1/2 z-20"
          onClick={() => {
            handleNext();
          }}
          disabled={isDisabled("next")}
        >
          <svg
            id="next-button-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={`${
              isDisabled("next")
                ? "fill-white stroke-white opacity-50"
                : "fill-white stroke-white"
            }`}
          >
            <polygon points="7.293 4.707 14.586 12 7.293 19.293 8.707 20.707 17.414 12 8.707 3.293 7.293 4.707" />
          </svg>
        </button>
        <div className="flex overflow-hidden snap-x scroll-smooth">
          {images}
        </div>
        <div className="absolute bottom-4 left-1/2 z-50 flex -translate-x-1/2 gap-2 navigation">
          {Array.from({ length: images.length }).map((_, index) => {
            return (
              <span
                className={`block h-1 cursor-pointer rounded-2xl transition-all contnet-[''] ${
                  activeIndex === index
                    ? "w-8 bg-gray-200"
                    : "w-4 bg-gray-200/50"
                }`}
                key={`navigation-${index}-navbtn${index}`}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};
