import { useEffect, useState } from "react";
import { getPrice } from "../../utilities/getSeasonPrice";
import { nextAvailableDate } from "../../utilities/nextDate";
import { BoatMiniSpecsComponent } from "./BoatMiniSpecsComponent";

export const BoatCardComponent = ({ boat }) => {
  const [available, setAvailable] = useState("");
  const nextAv = `${new Date().getDate()}/${
    new Date().getMonth() + 1
  }/${new Date().getFullYear()}`;
  useEffect(() => {
    nextAvailableDate(boat.name).then((res) => {
      setAvailable(res);
    });
  }, [boat]);
  return (
    <>
      <div
        className="flex flex-col justify-center align-middle"
        style={{
          backgroundColor: "#e2e2e2",
        }}
      >
        <div className="bg-red-500 text-white">
          Discount{" "}
          {boat.discount === undefined
            ? "(15%)"
            : `(-${boat.discount[0].toString()}%)`}
        </div>
        <img
          alt={`${boat.name}`}
          src={`./images/${boat.url_name}/image1.webp`}
          className="md:max-w-md md:h-96 max-h-96"
          style={{
            // height: "24rem",
            // width: "28rem",
            position: "center",
          }}
          loading="lazy"
        />
        <br></br>
        <p className="w-full text-center">{boat.name}</p>
        <br></br>
        <BoatMiniSpecsComponent boat={boat} features={boat.features} />
        <div className="p-2 mt-3 text-white text-left">
          <div className="flex justify-evenly align-middle items-center bg-slate-400">
            <p className="pr-2 text-black font-medium line-through text-sm">
              {getPrice(Date.now(), boat.og_price)}€
            </p>
            <p className="text-white text-xl underline font-semibold">
              {getPrice(Date.now(), boat.price)}€/week
            </p>
          </div>
          <div className="bg-yellow-400 text-sm text-black">
            Next available date:{" "}
            <span className="font-medium text-sm">{available || nextAv}</span>
          </div>
        </div>
      </div>
    </>
  );
};
