import { useLocation, useNavigate } from "react-router-dom";
import { Navigation } from "../../components/navigationBar/naviagationBar";

import { useState } from "react";
import { makeBooking } from "../../utilities/booking";
import { BoatMiniSpecsComponent } from "../../components/landingPageComponents/BoatMiniSpecsComponent";
import { getPrice } from "../../utilities/getSeasonPrice";
import Footer from "../../components/footer/Footer";

export const BookingPage = () => {
  const { state } = useLocation();
  const { boat, dates } = state;
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [remarks, setRemarks] = useState("");
  const [canSubmit, setSubmit] = useState(false);
  const startDate = `${dates[0].getFullYear()}/${String(
    dates[0].getMonth() + 1
  ).padStart(2, "0")}/${String(dates[0].getDate()).padStart(2, "0")}`;
  const endDate = `${dates[1].getFullYear()}/${String(
    dates[1].getMonth() + 1
  ).padStart(2, "0")}/${String(dates[1].getDate()).padStart(2, "0")}`;

  // const handleDateChange = (e, def, type) => {
  //   e.preventDefault();
  //   if (
  //     !validateDate(e.target.value) ||
  //     (new Date(endDate).getMonth() <= new Date(startDate).getMonth() &&
  //       new Date(endDate).getDate() <= new Date(startDate).getDate())
  //   ) {
  //     e.target.value = def;
  //     return;
  //   }
  //   switch (type) {
  //     case "START":
  //       setStartDate(e.target.value);
  //       break;
  //     case "END":
  //       setEndDate(e.target.value);
  //       break;
  //     default:
  //       return;
  //   }
  // };
  // const validateDate = (date) => {
  //   const day = new Date(date).getDay();
  //   if (day !== 6) return false;
  //   else return true;
  // };
  const validateForm = () => {
    const first_name_container = document.getElementById(
      "first_name_container"
    );
    if (firstName.length <= 1) {
      setSubmit(false);
      first_name_container.style.display = "grid";
      const error_message = document.createElement("span");
      error_message.textContent = "First name is required";

      if (first_name_container.children.length >= 1) {
        first_name_container.removeChild(first_name_container.firstChild);
      }
      first_name_container.appendChild(error_message);
    } else {
      setSubmit(true);
      first_name_container.style.display = "none";
    }
    const last_name_container = document.getElementById("last_name_container");
    if (lastName.length <= 1) {
      setSubmit(false);
      last_name_container.style.display = "grid";
      const error_message = document.createElement("span");
      error_message.textContent = "Last name is required";

      if (last_name_container.children.length >= 1) {
        last_name_container.removeChild(last_name_container.firstChild);
      }
      last_name_container.appendChild(error_message);
    } else {
      setSubmit(true);
      last_name_container.style.display = "none";
    }
    const email_container = document.getElementById("email_container");
    if (email.length <= 1) {
      setSubmit(false);
      email_container.style.display = "grid";
      const error_message = document.createElement("span");
      error_message.textContent = "Email is required";

      if (email_container.children.length >= 1) {
        email_container.removeChild(email_container.firstChild);
      }
      email_container.appendChild(error_message);
    } else {
      setSubmit(true);
      email_container.style.display = "none";
    }
    const phone_number_container = document.getElementById(
      "phone_number_container"
    );
    if (phone.length <= 1) {
      setSubmit(false);
      phone_number_container.style.display = "grid";
      const error_message = document.createElement("span");
      error_message.textContent = "Phone Number is required";
      if (phone_number_container.children.length >= 1) {
        phone_number_container.removeChild(phone_number_container.firstChild);
      }
      phone_number_container.appendChild(error_message);
    } else {
      setSubmit(true);
      phone_number_container.style.display = "none";
    }
  };
  const useHandleSubmit = (e) => {
    e.preventDefault();
    if (!canSubmit) return;
    makeBooking(
      boat.url_name,
      firstName,
      lastName,
      email,
      phone,
      remarks,
      startDate,
      endDate,
      boat
    ).then(async (_) => {
      return navigate("/booking/successful/" + boat.name, {
        state: { email: email, boat: boat.name },
      });
    });
  };
  return (
    <>
      <Navigation />
      <div className="flex flex-col gap-20 justify-center items-center min-h-screen bg-gray-100 p-6 ">
        <div className="max-w-7xl w-full mx-auto p-4 bg-white shadow-lg rounded-lg">
          <div className="lg:grid lg:grid-cols-1 lg:justify-items-stretch md:flex md:flex-col">
            {/* Thumbnail info price */}
            <div className="text-left p-5">
              {/* thumbnail info */}
              <div className="flex w-full md:justify-stretch md:flex-row flex-col">
                <img
                  key={`Boat ${boat.name}`}
                  src={`/images/${boat.url_name}/image1.webp`}
                  alt={`Boat ${boat.name}`}
                  className="w-fit md:w-52"
                />
                <div className="px-8 flex flex-col md:gap-4">
                  <div>
                    <p className="md:text-2xl text-sky-500 font-semibold">
                      {boat.name}
                    </p>
                  </div>
                  <div>
                    <BoatMiniSpecsComponent
                      boat={boat}
                      features={boat.features}
                    />
                  </div>
                  {/* Dates */}
                  <div className="flex flex-col justify-start gap-2 text-gray-500 font-medium bg-slate-100 p-2 rounded-lg">
                    <p>Check in: {startDate}, 17:00</p>
                    <p>Check out: {endDate}, 09:00</p>
                  </div>
                </div>
              </div>
            </div>
            {/* Due amount */}
            <div className="bg-sky-200 w-full rounded-xl p-2">
              <div className="p-5 pb-0 pt-0 flex justify-between border-gray-400 border-b-2">
                <div className="w-full  p-4">
                  <p>Base price</p>
                </div>
                <div className=" p-4">
                  <p className="font-medium">
                    {getPrice(startDate, boat.og_price)}€
                  </p>
                </div>
              </div>
              <div className="p-5 pt-0 pb-0 flex justify-between bg-sky-200 w-full border-b-2 border-b-gray-400">
                <div className="bg-sky-200 w-screen p-4">
                  <p>Season Discount (15%)</p>
                </div>
                <div className="bg-sky-200 p-4 font-medium">
                  <p>
                    -
                    {parseInt(
                      getPrice(startDate, boat.og_price).split(",").join("")
                    ) * 0.15}
                    €
                  </p>
                </div>
              </div>
              <div className="p-5 pt-0 pb-0 flex justify-between bg-sky-200 w-full border-b-2 border-b-gray-400">
                <div className="bg-sky-200 w-screen p-4">
                  <p>Final cleaning</p>
                </div>
                <div className="bg-sky-200 p-4 font-medium">
                  <p>{boat.obligatory}€</p>
                </div>
              </div>
              <div className="p-5 pt-0 pb-0 flex justify-between bg-sky-200 w-full">
                <div className="bg-sky-200 w-screen p-4">
                  <p className="font-bold">Due amount</p>
                </div>
                <div className="bg-sky-200 p-4">
                  <p className="font-bold">
                    {parseInt(
                      getPrice(startDate, boat.price).split(",").join("")
                    ) + parseInt(boat.obligatory)}
                    €
                  </p>
                </div>
              </div>
            </div>
            {/* Form */}
            <div className="p-5">
              <form
                className=" mx-auto grid grid-flow-row grid-rows-2"
                onSubmit={useHandleSubmit}
              >
                <div className="mb-5">
                  <div
                    id="first_name_container"
                    className="hidden text-red-500 font-sm rounded-sm"
                  ></div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-md font-medium text-gray-900 "
                  >
                    First Name
                  </label>
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    id="first_name"
                    name="first_name"
                    placeholder="John"
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-5">
                  <div
                    id="last_name_container"
                    className="hidden text-red-500 font-sm rounded-sm"
                  ></div>
                  <label
                    htmlFor="last_name"
                    className="block mb-2 text-md font-medium text-gray-900"
                  >
                    Last Name
                  </label>
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    name="last_name"
                    id="last_name"
                    placeholder="Doe"
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-5">
                  <div
                    id="email_container"
                    className="hidden text-red-500 font-sm rounded-sm"
                  ></div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-md font-medium text-gray-900"
                  >
                    Email Address
                  </label>
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email adress"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="mb-5">
                  <div
                    id="phone_number_container"
                    className="hidden text-red-500 font-sm rounded-sm"
                  ></div>
                  <label
                    htmlFor="phone_number"
                    className="block mb-2 text-md font-medium text-gray-900 "
                  >
                    Phone Number
                  </label>
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="tel"
                    name="phone_number"
                    id="phone_number"
                    placeholder="Phone Number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
                <div className="mb-5">
                  <label
                    htmlFor="remarks"
                    className="block mb-2 text-md font-medium text-gray-900 "
                  >
                    Remarks
                  </label>
                  <textarea
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 resize-none"
                    name="remarks"
                    id="remarks"
                    placeholder="Remarks"
                    rows={5}
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </div>
                <button
                  className="w-full bg-blue-500 p-4 rounded-lg font-black text-white"
                  // type="submit"
                  onClick={validateForm}
                >
                  Pre-book now!
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
