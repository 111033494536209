import { useEffect, useState } from "react";

import { makeRequestBoatData } from "../../utilities/requestBoatData";
import { HomePageComponent } from "../../components/landingPageComponents/HomePageComponent";
import { Loading } from "../loading/Loading";

export const Home = () => {
  const [boatArray, setBoatArray] = useState([]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    makeRequestBoatData().then((res) => {
      setBoatArray(res);
      setLoaded(true);
    });
  }, []);
  return (
    <>{loaded ? <HomePageComponent boatArray={boatArray} /> : <Loading />}</>
  );
};
