import { Link } from "react-router-dom";
import { Navigation } from "../navigationBar/naviagationBar";
import { BoatCardComponent } from "../landingPageComponents/BoatCardComponent";
import Footer from "../footer/Footer";
export const YachtListingsComponent = ({ boatArray }) => {
  return (
    <main className="bg-gray-200/15">
      <Navigation />

      <div className=" border-b-2">
        <div
          className="text-center md:text-lg md:font-semibold flex justify-center align-middle items-center md:m-5  md:p-5 p-3 m-2 bg-gray-400/10 rounded-md text-sm md:h-1/4"
          // style={{ height: "25vh" }}
        >
          <p className="md:w-1/2 text-black/50 md:p-4 p-2">
            Finding the ideal boat charter holiday is made simple with
            HeavenOfSails. We create the most complete yacht charter website in
            the world by fusing a plethora of yacht listings with local location
            details, example itineraries, and experiences. We can help you
            select the ideal sailing yacht for your vacations by working with
            JFYachts to ensure it meets your demands.Reach out to us
            immediately.
          </p>
        </div>
      </div>

      <div
        className={`grid items-center justify-items-center  2xl:grid-cols-3 lg:grid-cols-2 gap-4 text-center md:text-xl md:p-5 grid-cols-1`}
      >
        {boatArray
          .sort((a, b) => b.carousel_images - a.carousel_images)
          .map((boat, index) => {
            return (
              <Link
                key={index}
                to={`/boats/${boat.url_name}`}
                className="text-center rounded-lg md:p-6 p-2 md:m-0 m-2 drop-shadow-xl shadow-lg hover:shadow hover:drop-shadow  hover:shadow-black hover:scale-95 transition-all"
              >
                <BoatCardComponent boat={boat} />
              </Link>
            );
          })}
      </div>
      <Footer />
    </main>
  );
};
